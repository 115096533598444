import React, { useState } from "react";
import PageTitle from "../page-title/page-title";
import SubService from "./service-card/subservice/subService";
import { useServices } from "../../contexts/services.context";
import { useAuth } from "../../contexts/auth.context";
import "./services.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faCheck, faTimes, faPlus } from "@fortawesome/free-solid-svg-icons";

const Services = () => {
  const { services, loading, handleAddService, handleDeleteService, handleUpdateService } = useServices();
  const { currentUser } = useAuth();
  const [newTitle, setNewTitle] = useState("");
  const [editTitle, setEditTitle] = useState("");
  const [editingId, setEditingId] = useState(null);

  const handleAddServiceClick = async () => {
    if (!newTitle.trim()) return;

    const newService = {
      title: newTitle,
      order: services.length + 1,
      items: [],
    };

    await handleAddService(newService);
    setNewTitle("");
  };

  const handleEditClick = (service) => {
    setEditingId(service.id);
    setEditTitle(service.title);
  };

  const handleSaveEdit = async (serviceId) => {
    if (!editTitle.trim()) return;
    await handleUpdateService(serviceId, editTitle);
    setEditingId(null);
  };

  if (loading) {
    return <div className="loading">Loading...</div>;
  }


  return (
    <div className="services-container">
      <PageTitle title="Our Services" />
      <section id="advertisers" className="advertisers-service-sec pt-5 pb-5">
        <div className="container">
          {services.map((service) => (
            <div key={service.id}>
              {currentUser && (editingId === service.id ? (
                <div className="edit-mode">
                  <input
                    type="text"
                    value={editTitle}
                    onChange={(e) => setEditTitle(e.target.value)}
                  />
                  <button className="save-btn" onClick={() => handleSaveEdit(service.id)}>
                    <FontAwesomeIcon icon={faCheck} />
                  </button>
                  <button className="cancel-btn" onClick={() => setEditingId(null)}>
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </div>
              ) : (
                <div className="service-title-actions">
                  <h3 className="service-title">{service.title}</h3>
                  <div className="service-actions">
                    <button className="edit-btn" onClick={() => handleEditClick(service)}>
                      <FontAwesomeIcon icon={faEdit} />
                    </button>
                    <button className="delete-btn" onClick={() => handleDeleteService(service.id)}>
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </div>
                </div>
              ))}
              <SubService subServiceTitle={service.title} services={service.items} />
            </div>
          ))}
        </div>
      </section>
      {currentUser && (
        <div className="add-service-form">
          <input
            type="text"
            placeholder="New Service Title"
            value={newTitle}
            onChange={(e) => setNewTitle(e.target.value)}
          />
          <button className="add-btn" onClick={handleAddServiceClick}>
            <FontAwesomeIcon icon={faPlus} /> Add Service
          </button>
        </div>
      )}
    </div>
  );
};

export default Services;

import React, { useState, useEffect, createContext, useContext } from "react";
import { fetchServices, addService, deleteService, updateService } from "../utils/firebase/firebase.utils"; // Adjust based on your file structure

const ServicesContext = createContext();

export const useServices = () => useContext(ServicesContext);

export const ServicesProvider = ({ children }) => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getServices = async () => {
      try {
        const servicesData = await fetchServices();
        const sortedServices = servicesData.sort((a, b) => a.order - b.order);
        setServices(sortedServices);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching services: ", error);
      }
    };

    getServices();
  }, []);

  const handleAddService = async (newService) => {
    const addedService = await addService(newService);
    setServices(prevServices => [...prevServices, addedService].sort((a, b) => a.order - b.order));
  };

  const handleDeleteService = async (id) => {
    await deleteService(id);
    setServices(prevServices => prevServices.filter(service => service.id !== id));
  };

  const handleUpdateService = async (serviceId, newTitle) => {
    try {
      await updateService(serviceId, { title: newTitle });
      setServices(
        services.map((service) =>
          service.id === serviceId ? { ...service, title: newTitle } : service
        )
      );
    } catch (error) {
      console.error("Error updating service:", error);
    }
  };

  return (
    <ServicesContext.Provider value={{ services, loading, handleAddService, handleDeleteService, handleUpdateService }}>
      {children}
    </ServicesContext.Provider>
  );
};

import { initializeApp } from "firebase/app";
import { getFirestore, doc, getDoc, setDoc,collection, addDoc, updateDoc, deleteDoc, getDocs } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut} from "firebase/auth";
// import { getFirestore, doc, getDoc, setDoc, collection, writeBatch, query, getDocs } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAQLXhzSLCjQYwY2CwOTyEMP-Cv6psVDK4",
  authDomain: "abs-lb.firebaseapp.com",
  projectId: "abs-lb",
  storageBucket: "abs-lb.appspot.com",
  messagingSenderId: "1030551106291",
  appId: "1:1030551106291:web:da30c70d9e1ceddb087d86"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth();

export { db, storage, collection, addDoc, doc, getDoc, setDoc, updateDoc, deleteDoc, ref, uploadBytes, getDownloadURL, auth };


export const createUserDocumentFromAuth = async (userAuth, additionalInfo = {}) => {
    if (!userAuth) return;
    const userRefDoc = doc(db, 'users', userAuth.uid);
    const userSnapshot = await getDoc(userRefDoc);
    if (!userSnapshot.exists()) {
        const { displayName, email } = userAuth;
        const createdAt = new Date();

        try {
            await setDoc(userRefDoc, {
                displayName,
                email,
                createdAt,
                ...additionalInfo
            });
        }
        catch (error) {
            console.log('error creating user', error.message);
        }
    }
    return userRefDoc;

};


export const createAuthUserWithEmailAndPassword = async (email, password) => {
    if (!email || !password) return;
    return await createUserWithEmailAndPassword(auth, email, password);
};

export const signinAuthUserWithEmailAndPassword = async (email, password) => {
    if (!email || !password) return;
    return await signInWithEmailAndPassword(auth, email, password);
};

export const signoutUser = async () => await signOut(auth);

// export const addService = async (newService) => {
//     const docRef = await addDoc(collection(db, "services"), newService);
//     return { id: docRef.id, ...newService };
// };

// export const deleteService = async (id) => {
//     await deleteDoc(doc(db, "services", id));
// };

// export const updateService = async (id, updatedService) => {
//     await updateDoc(doc(db, "services", id), updatedService);
//     return { id, ...updatedService };
// };

export const fetchServices = async () => {
  const servicesCollection = collection(db, "services");
  const servicesSnapshot = await getDocs(servicesCollection);
  const servicesData = servicesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  return servicesData;
};

export const addService = async (newService) => {
  const docRef = await addDoc(collection(db, "services"), newService);
  return { id: docRef.id, ...newService };
};

export const deleteService = async (id) => {
  await deleteDoc(doc(db, "services", id));
};

export const updateService = async (serviceId, updatedFields) => {
  const serviceDoc = doc(db, "services", serviceId);
  await updateDoc(serviceDoc, updatedFields);
};